<template>
  <div>
    <!-- barang keluar -->
    <section class="bg-white rounded shadow-sm py-3 px-5 mb-3">
      <header class="kop-surat row">
        <div class="col-md-2 d-flex justify-content-center align-items-center">
          <img
            :src="require('@/assets/img/logo/logo.svg')"
            alt="Company Logo"
            width="50"
            height="50"
          />
        </div>
        <div class="col-md-10">
          <h2 class="fs-4 m-0">PT JASTEL MEDIKA UTAMA</h2>
          <address class="fs-6 mb-1">
            Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921<br />
            Tlp: 081312856201<br />
            Email: RQGpH@example.com
          </address>
        </div>
      </header>

      <hr />

      <section class="surat-jalan mb-3">
        <h3 class="text-center">Surat Jalan</h3>
        <div class="d-flex justify-content-between">
          <div>
            <p class="mb-0">Kepada</p>
            <h3 class="">{{ requester }}</h3>
          </div>

          <table>
            <tbody>
              <tr>
                <th scope="row" class="p-0">Nomor Surat Jalan</th>
                <td class="p-0 pl-3">: {{ nomorNota }}</td>
              </tr>
              <tr>
                <th scope="row" class="p-0">Tanggal Surat Jalan</th>
                <td class="p-0 pl-3">: {{ NotaOutgoingDate }}</td>
              </tr>
              <tr>
                <th scope="row" class="p-0">Kode Dept:</th>
                <td class="p-0 pl-3">: -</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Nama Barang</th>
            <th scope="col">Kode Barang</th>
            <th scope="col">Nomor Batch</th>
            <th scope="col">Kuantitas</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in outgoing_goods_type_inventory"
            :key="item.id"
          >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.item_name }}</td>
            <td>{{ item.item_code }}</td>
            <td>{{ item.batch }}</td>
            <td>{{ item.total }}</td>
          </tr>
        </tbody>
      </table>
      <p>Catatan: {{ noteOutgoing }}</p>

      <footer class="d-flex align-items-center justify-content-around">
        <div class="text-center">
          <p class="mt-3">Penerima / Pembeli</p>
          <div style="height: 3rem"></div>
          <hr />
        </div>
        <div class="text-center">
          <p class="mt-3">Bagian Pengirim</p>
          <div style="height: 3rem"></div>
          <hr />
        </div>
      </footer>
    </section>

    <div class="html2pdf__page-break"></div>

    <!-- barang pengembalian -->
    <section
      v-if="
        returned_goods_type_inventory &&
        returned_goods_type_inventory.length > 0
      "
      class="bg-white rounded shadow-sm px-5 py-3 mb-3"
    >
      <header class="kop-surat row">
        <div class="col-md-2 d-flex justify-content-center align-items-center">
          <img
            :src="require('@/assets/img/logo/logo.svg')"
            alt="Company Logo"
            width="50"
            height="50"
          />
        </div>
        <div class="col-md-10">
          <h2 class="fs-4 m-0">PT JASTEL MEDIKA UTAMA</h2>
          <address class="fs-6 mb-1">
            Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921<br />
            Tlp: 081312856201<br />
            Email: RQGpH@example.com
          </address>
        </div>
      </header>

      <hr />

      <section class="surat-jalan mb-3">
        <h3 class="text-center">Surat Pengembalian</h3>
        <div class="d-flex justify-content-between">
          <div>
            <p class="mb-0">Dari</p>
            <h3 class="">{{ sender }}</h3>
          </div>

          <table>
            <tbody>
              <tr>
                <th scope="row" class="p-0">Nomor Surat Jalan</th>
                <td class="p-0 pl-3">: {{ nomorNota }}</td>
              </tr>

              <tr>
                <th scope="row" class="p-0">Kode Dept:</th>
                <td class="p-0 pl-3">: -</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Nama Barang</th>
            <th scope="col">Kode Barang</th>
            <th scope="col">Nomor Batch</th>
            <th scope="col">Kuantitas</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in returned_goods_type_inventory"
            :key="item.id"
          >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.item_name }}</td>
            <td>{{ item.item_code }}</td>
            <td>{{ item.batch }}</td>
            <td>{{ item.total }}</td>
          </tr>
        </tbody>
      </table>
      <p>Catatan: {{ noteReturn }}</p>

      <footer class="d-flex align-items-center justify-content-around">
        <div class="text-center">
          <p class="mt-3">Penerima / Pembeli</p>
          <div style="height: 3rem"></div>
          <hr />
        </div>
        <div class="text-center">
          <p class="mt-3">Bagian Pengirim</p>
          <div style="height: 3rem"></div>
          <hr />
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: "HasilCetak",

  props: {
    nomorNota: String,
    requester: String,
    sender: String,
    noteOutgoing: String,
    NotaOutgoingDate: String,
    outgoing_goods_type_inventory: Array,
    returned_goods_type_inventory: Array,
    noteReturn: String,
  },
};
</script>
