<template>
  <div>
    <h2 class="float-left">Pengembalian Barang</h2>
    <h4 class="text-muted text-right mb-5">{{ nomorNota }}</h4>
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Pengirim:">
        <b-form-input
          v-model="form.sender"
          placeholder="Masukkan Nama pengirim"
        />
      </b-form-group>

      <b-form-group label="Catatan:">
        <b-form-textarea
          v-model="form.note"
          rows="3"
          placeholder="Masukkan Catatan"
        />
      </b-form-group>

      <b-table striped hover :items="table.items" :fields="table.fields">
        <template #cell(qty)="{ item }">
          <b-form-input type="number" v-model="item.qty" min="0" />
        </template>
      </b-table>

      <p class="mb-5 text-right">
        <strong>Jumlah Item:</strong> {{ totalItems }}<br />
        <strong>Total Qty:</strong> {{ totalQty }}
      </p>

      <div class="d-flex justify-content-end">
        <base-button
          v-if="isHiddenButton === false"
          text="Simpan"
          :is-busy="isBusy"
          :disabled="totalQty === 0 || !form.sender"
          variant="success"
          @click="showModal = true"
        />
      </div>
    </b-form>

    <b-modal
      v-model="showModal"
      title="Konfirmasi"
      ok-title="Ya"
      cancel-title="Tidak"
      @ok="handleSubmit"
    >
      <p>Apakah anda yakin ingin menyimpan data pengembalian barang ini?</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import outgoingGoodsAPI from "../../../../../../api/outgoinggoods/outgoingGoodsAPI";

export default {
  name: "FormBarangKeluar",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
  },
  props: {
    nomorNota: String,
    nota_id: String,
    sender: String,
    note: String,
    outgoing_goods_type_inventory: Array,
    returned_goods_type_inventory: Array,
    isHiddenButton: Boolean,
  },
  data() {
    return {
      showModal: false,
      form: { sender: this.sender, note: this.note },
      table: {
        items: this.outgoing_goods_type_inventory.map((item) => {
          const returnedItem = this.returned_goods_type_inventory.find(
            (i) => i.batch === item.batch
          );
          return {
            ...item,
            qty: returnedItem ? returnedItem.total : 0,
          };
        }),
        fields: [
          { key: "item_name", label: "Nama" },
          { key: "item_code", label: "Kode" },
          { key: "batch", label: "Batch" },
          { key: "qty", label: "QTY Kembali" },
        ],
      },
      isBusy: false,
    };
  },
  computed: {
    totalItems() {
      return this.table.items.length;
    },
    totalQty() {
      return this.table.items.reduce(
        (total, { qty = 0 }) => total + Number(qty),
        0
      );
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isBusy = true;
        const payload = {
          nota_id: this.nota_id,
          sender: this.form.sender,
          note: this.form.note,
          items: this.table.items,
        };

        const { data: response } = await outgoingGoodsAPI.addReturnNota(
          payload
        );

        if (!response) {
          this.$bvToast.toast("Pengembalian barang gagal", {
            title: "Gagal",
            variant: "danger",
            solid: true,
          });
        }
        this.$bvToast.toast(`Pengembalian barang berhasil di simpan`, {
          title: "Berhasil",
          variant: "success",
          solid: true,
        });
        this.$router.push({ name: "list_outgoing_goods" });
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
