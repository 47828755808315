<template>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <base-button
        v-if="mode === 'print'"
        text="Cetak Nota"
        variant="outline-primary"
        :disabled="isBusy"
        @click="onPrint()"
      />
    </div>

    <section v-if="mode === 'print'">
      <HasilCetak
        id="pdf-content"
        :nomorNota="id"
        :requester="notaDetail.outgoing_goods_type_inventory.receiver"
        :NotaOutgoingDate="notaDetail.created_at"
        :noteOutgoing="notaDetail.outgoing_goods_type_inventory.note"
        :outgoing_goods_type_inventory="
          notaDetail.outgoing_goods_type_inventory.items
        "
        :sender="notaDetail.returned_goods_type_inventory.sender"
        :noteReturn="notaDetail.returned_goods_type_inventory.note"
        :returned_goods_type_inventory="
          notaDetail.returned_goods_type_inventory.items
        "
      />
    </section>

    <section class="bg-white rounded shadow-sm p-3" v-if="mode === 'add'">
      <FormTambahBarangKeluar />
    </section>

    <content>
      <section class="bg-white rounded shadow-sm p-3" v-if="mode === 'detail'">
        <FormBarangKeluar
          :nomorNota="id"
          :created_at="notaDetail.created_at"
          :nota_id="notaDetail.id"
          :requester="notaDetail.requester"
          :inventoryItems="inventoryItems"
          :reciver="notaDetail.outgoing_goods_type_inventory.receiver"
          :status="notaDetail.outgoing_goods_type_inventory.status"
          :note="notaDetail.outgoing_goods_type_inventory.note"
          :outgoing_goods_type_inventory="
            notaDetail.outgoing_goods_type_inventory.items
          "
          :returned_goods_type_inventory="
            notaDetail.returned_goods_type_inventory.items
          "
          :isHiddenButton="isHiddenButton"
        />
      </section>

      <div class="html2pdf__page-break"></div>

      <section
        class="bg-white rounded shadow-sm p-3 mt-5"
        v-if="mode === 'detail'"
      >
        <FormPengembalianBarang
          :nomorNota="id"
          :nota_id="notaDetail.id"
          :sender="notaDetail.returned_goods_type_inventory.sender"
          :note="notaDetail.returned_goods_type_inventory.note"
          :outgoing_goods_type_inventory="
            notaDetail.outgoing_goods_type_inventory.items
          "
          :returned_goods_type_inventory="
            notaDetail.returned_goods_type_inventory.items
          "
          :isHiddenButton="isHiddenButton"
        />
      </section>
    </content>
  </div>
</template>

<script>
import FormBarangKeluar from "./components/FormBarangKeluar.vue";
import FormPengembalianBarang from "./components/FormPengembalianBarang.vue";
import outgoingGoodsTypeInventoryAPI from "../../../../../api/outgoinggoods/outgoingGoodsAPI";
import FormTambahBarangKeluar from "./components/FormTambahBarangKeluar.vue";
import html2pdf from "html2pdf.js";
import HasilCetak from "./components/HasilCetak.vue";

export default {
  name: "Form",
  components: {
    FormBarangKeluar,
    FormPengembalianBarang,
    FormTambahBarangKeluar,
    HasilCetak,
  },

  data() {
    return {
      isHiddenButton: false,
      isBusy: false,
      mode: "add",
      id: this.$route.params.id || null,
      notaDetail: null,
      inventoryItems: [],
    };
  },
  methods: {
    setInitialMode() {
      const route = this.$route.path;
      if (route.includes("/tambah")) {
        this.mode = "add";
      } else if (route.includes("/detail")) {
        this.mode = "detail";
        this.getDetail(this.id);
      } else if (route.includes("/cetak-nota")) {
        this.mode = "print";
        this.getDetail(this.id);
      }
    },
    async getDetail(id) {
      const { data: response } =
        await outgoingGoodsTypeInventoryAPI.getDetailOutgoingGoods(id);
      this.notaDetail = response.data;
    },
    onPrint() {
      this.isHiddenButton = true;
      this.isBusy = true;

      const options = {
        margin: 0,
        filename: `Nota_${this.notaDetail.requester}_${this.$route.params.id}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf()
        .from(document.querySelector("#pdf-content"))
        .set(options)
        .save()
        .finally(() => {
          this.isBusy = false;
          this.isHiddenButton = false;
        });
    },
  },
  mounted() {
    this.setInitialMode();
  },
};
</script>
