<template>
  <div>
    <h2 class="float-left">Barang Keluar</h2>
    <h4 class="text-muted text-right">{{ nomorNota }}</h4>
    <p class="m-0 font-weight-bold text-muted text-right">
      {{ created_at }}
    </p>
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Nama Penerima:" label-for="reciver-input">
        <b-form-input
          id="reciver-input"
          v-model="form.reciver"
          placeholder="Masukkan nama penerima"
        />
      </b-form-group>
      <b-form-group label="Pemohon:">
        <b-form-input
          v-model="form.requester"
          placeholder="Masukkan nama pemohon"
        />
      </b-form-group>
      <b-form-group label="Status:" label-for="status-select">
        <b-form-select
          id="status-select"
          v-model="form.status"
          :options="[
            { text: 'Barang Keluar', value: 'outgoing' },
            { text: 'Return Vendor', value: 'return' },
          ]"
          disabled
        />
      </b-form-group>

      <b-form-group label="Catatan:">
        <b-form-textarea
          v-model="form.note"
          rows="3"
          placeholder="Masukkan Catatan"
        />
      </b-form-group>

      <b-table striped hover :items="table.items" :fields="table.fields">
        <template #cell(qty)="{ item }">
          <b-form-input type="number" v-model="item.qty" min="0" />
        </template>
      </b-table>

      <p class="mb-5 text-right">
        <strong>Jumlah Item:</strong> {{ totalItems }}<br />
        <strong>Total Qty:</strong> {{ totalQty }}
      </p>

      <div class="d-flex justify-content-end">
        <base-button
          v-if="isHiddenButton === false"
          text="Simpan"
          :is-busy="isBusy"
          variant="success"
          @click="showModal = true"
        />
      </div>
    </b-form>

    <b-modal
      v-model="showModal"
      title="Konfirmasi"
      ok-title="Ya"
      cancel-title="Tidak"
      @ok="handleSubmit"
    >
      <p>Apakah anda yakin ingin mengubah data ini?</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import outgoingGoodsAPI from "../../../../../../api/outgoinggoods/outgoingGoodsAPI";

export default {
  name: "FormBarangKeluar",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BTable,
  },
  props: {
    nomorNota: String,
    created_at: String,
    nota_id: String,
    reciver: String,
    status: String,
    note: String,
    requester: String,
    outgoing_goods_type_inventory: Array,
    isHiddenButton: Boolean,
  },
  data() {
    return {
      showModal: false,
      form: {
        reciver: this.reciver,
        requester: this.requester,
        status: this.status,
        note: this.note,
      },
      table: {
        items: this.outgoing_goods_type_inventory.map((item) => ({
          ...item,
          qty: item.total,
        })),
        fields: [
          { key: "item_name", label: "Nama" },
          { key: "item_code", label: "Kode" },
          { key: "batch", label: "Batch" },
          { key: "qty", label: "QTY" },
        ],
      },
      isBusy: false,
    };
  },
  computed: {
    totalItems() {
      return this.table.items.length;
    },
    totalQty() {
      return this.table.items.reduce(
        (total, { total: itemTotal = 0 }) => total + Number(itemTotal),
        0
      );
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isBusy = true;
        const payload = {
          id: this.nota_id,
          no: this.$route.params.id,
          requester: this.form.requester,
          receiver: this.form.reciver,
          status: this.form.status,
          note: this.form.note,
          items: this.table.items,
        };

        const { data: response } = await outgoingGoodsAPI.edit(payload);
        if (response.status === "success") {
          this.$toast.success(response.message);
          this.$router.push({ name: "outgoinggoods" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
