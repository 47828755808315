var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg-white rounded shadow-sm py-3 px-5 mb-3"},[_c('header',{staticClass:"kop-surat row"},[_c('div',{staticClass:"col-md-2 d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":require('@/assets/img/logo/logo.svg'),"alt":"Company Logo","width":"50","height":"50"}})]),_vm._m(0)]),_c('hr'),_c('section',{staticClass:"surat-jalan mb-3"},[_c('h3',{staticClass:"text-center"},[_vm._v("Surat Jalan")]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Kepada")]),_c('h3',{},[_vm._v(_vm._s(_vm.requester))])]),_c('table',[_c('tbody',[_c('tr',[_c('th',{staticClass:"p-0",attrs:{"scope":"row"}},[_vm._v("Nomor Surat Jalan")]),_c('td',{staticClass:"p-0 pl-3"},[_vm._v(": "+_vm._s(_vm.nomorNota))])]),_c('tr',[_c('th',{staticClass:"p-0",attrs:{"scope":"row"}},[_vm._v("Tanggal Surat Jalan")]),_c('td',{staticClass:"p-0 pl-3"},[_vm._v(": "+_vm._s(_vm.NotaOutgoingDate))])]),_vm._m(1)])])])]),_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(2),_c('tbody',_vm._l((_vm.outgoing_goods_type_inventory),function(item,index){return _c('tr',{key:item.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.item_name))]),_c('td',[_vm._v(_vm._s(item.item_code))]),_c('td',[_vm._v(_vm._s(item.batch))]),_c('td',[_vm._v(_vm._s(item.total))])])}),0)]),_c('p',[_vm._v("Catatan: "+_vm._s(_vm.noteOutgoing))]),_vm._m(3)]),_c('div',{staticClass:"html2pdf__page-break"}),(
      _vm.returned_goods_type_inventory &&
      _vm.returned_goods_type_inventory.length > 0
    )?_c('section',{staticClass:"bg-white rounded shadow-sm px-5 py-3 mb-3"},[_c('header',{staticClass:"kop-surat row"},[_c('div',{staticClass:"col-md-2 d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":require('@/assets/img/logo/logo.svg'),"alt":"Company Logo","width":"50","height":"50"}})]),_vm._m(4)]),_c('hr'),_c('section',{staticClass:"surat-jalan mb-3"},[_c('h3',{staticClass:"text-center"},[_vm._v("Surat Pengembalian")]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Dari")]),_c('h3',{},[_vm._v(_vm._s(_vm.sender))])]),_c('table',[_c('tbody',[_c('tr',[_c('th',{staticClass:"p-0",attrs:{"scope":"row"}},[_vm._v("Nomor Surat Jalan")]),_c('td',{staticClass:"p-0 pl-3"},[_vm._v(": "+_vm._s(_vm.nomorNota))])]),_vm._m(5)])])])]),_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(6),_c('tbody',_vm._l((_vm.returned_goods_type_inventory),function(item,index){return _c('tr',{key:item.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.item_name))]),_c('td',[_vm._v(_vm._s(item.item_code))]),_c('td',[_vm._v(_vm._s(item.batch))]),_c('td',[_vm._v(_vm._s(item.total))])])}),0)]),_c('p',[_vm._v("Catatan: "+_vm._s(_vm.noteReturn))]),_vm._m(7)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10"},[_c('h2',{staticClass:"fs-4 m-0"},[_vm._v("PT JASTEL MEDIKA UTAMA")]),_c('address',{staticClass:"fs-6 mb-1"},[_vm._v(" Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921"),_c('br'),_vm._v(" Tlp: 081312856201"),_c('br'),_vm._v(" Email: RQGpH@example.com ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"p-0",attrs:{"scope":"row"}},[_vm._v("Kode Dept:")]),_c('td',{staticClass:"p-0 pl-3"},[_vm._v(": -")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("No")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nama Barang")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kode Barang")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nomor Batch")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kuantitas")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"d-flex align-items-center justify-content-around"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mt-3"},[_vm._v("Penerima / Pembeli")]),_c('div',{staticStyle:{"height":"3rem"}}),_c('hr')]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mt-3"},[_vm._v("Bagian Pengirim")]),_c('div',{staticStyle:{"height":"3rem"}}),_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10"},[_c('h2',{staticClass:"fs-4 m-0"},[_vm._v("PT JASTEL MEDIKA UTAMA")]),_c('address',{staticClass:"fs-6 mb-1"},[_vm._v(" Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921"),_c('br'),_vm._v(" Tlp: 081312856201"),_c('br'),_vm._v(" Email: RQGpH@example.com ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"p-0",attrs:{"scope":"row"}},[_vm._v("Kode Dept:")]),_c('td',{staticClass:"p-0 pl-3"},[_vm._v(": -")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("No")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nama Barang")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kode Barang")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nomor Batch")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kuantitas")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"d-flex align-items-center justify-content-around"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mt-3"},[_vm._v("Penerima / Pembeli")]),_c('div',{staticStyle:{"height":"3rem"}}),_c('hr')]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mt-3"},[_vm._v("Bagian Pengirim")]),_c('div',{staticStyle:{"height":"3rem"}}),_c('hr')])])
}]

export { render, staticRenderFns }