<template>
  <div>
    <b-row class="justify-content-between mb-3">
      <b-col>
        <h3 class="mb-3">Tambah Data Barang Keluar</h3>
      </b-col>
    </b-row>

    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Pemohon:">
        <b-form-select v-model="nota.requester" :options="[
          { text: 'Pilih Pemohon', value: '' },
          { text: 'Divisi IT', value: 'IT' },
          { text: 'Divisi Keuangan', value: 'Keuangan' },
          { text: 'Divisi Humas', value: 'Humas' },
          { text: 'Divisi Pemasaran', value: 'Pemasaran' },
          { text: 'Divisi Logistik', value: 'Logistik' },
          { text: 'Divisi Layanan', value: 'Layanan' },
          { text: 'Divisi Operasional', value: 'Operasional' },
          { text: 'Divisi Produksi', value: 'Produksi' },
          { text: 'Divisi Akuntansi', value: 'Akuntansi' },
        ]" />
      </b-form-group>
      <b-form-group label="Penerima:">
        <b-form-input v-model="nota.receiver" placeholder="Masukkan Nama Penerima" />
      </b-form-group>
      <b-form-group label="Status:">
        <b-form-select v-model="nota.status" :options="[
          { value: '', text: 'Pilih Status' },
          { value: 'outgoing', text: 'Barang Keluar' },
          {
            value: 'return',
            text: 'Return Barang',
            disabled: outgoingStock.items.some(
              (item) => item.incoming_stock !== item.current_stock
            ),
          },
        ]" />
      </b-form-group>
      <b-form-group label="Catatan:">
        <b-form-textarea v-model="nota.note" rows="3" placeholder="Masukkan Catatan" />
      </b-form-group>

      <b-table striped hover :items="outgoingStock.items" :fields="fields" responsive="sm">
        <template #cell(item_name)="{ item, index }">
          <v-select v-model="item.item_name" style="min-width: 160px;" :options="itemOptions"
            placeholder="Pilih Nama Barang" @input="() => updateItem(index, 'item_name')" />
        </template>
        <template #cell(item_code)="{ item, index }">
          <v-select v-model="item.item_code" style="min-width: 160px; width: 100%;" :options="item.filteredCodeOptions"
            placeholder="Pilih Kode Barang" @input="() => updateItem(index, 'item_code')" />
        </template>
        <template #cell(batch)="{ item, index }">
          <v-select v-model="item.batch" :options="item.filteredBatchOptions" placeholder="Pilih Batch"
            style="min-width: 120px;" @input="() => updateItem(index, 'batch')" :reduce="(option) => option.value"
            :get-option-label="(option) => option.label" />
        </template>
        <template #cell(current_stock)="{ item }">
          <p class="mb-0">{{ item.current_stock }}</p>
        </template>
        <template #cell(qty)="{ item }">
          <b-form-input v-model.number="item.qty" type="number" min="1" :max="item.current_stock" class="safari-input"
            style="min-width: 80px;" placeholder="QTY" :disabled="isDetailOrEdit || (isReturnStatus && item.batch)" />
        </template>
        <template #cell(actions)="{ index }">
          <b-button variant="link" class="p-0" @click="removeItem(index)">
            <i class="ri-close-circle-fill" style="font-size: 1.5rem; color: #dc3545" />
          </b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-between px-3 mb-5">
        <b-button variant="primary" @click="addItem" class="mb-4">
          Tambah Barang
        </b-button>

        <p class="mb-0 text-right">
          <strong>Jumlah Item: {{ outgoingStock.items.length }}</strong>
          <br />
          <strong>Total Qty: {{ totalQty }}</strong>
        </p>
      </div>

      <div class="d-flex justify-content-end" v-if="mode === 'add'">
        <base-button text="Simpan" :is-busy="isBusy" :loading="isBusy" variant="success" submit-type="submit"
          class="mr-2" :disabled="totalQty === 0" />
      </div>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import incomingGoodsTypeInventoryAPI from "@/api/incominggoodstypeinventory/incomingGoodsTypeInventoryAPI";
import outgoingGoodsAPI from "../../../../../../api/outgoinggoods/outgoingGoodsAPI";
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BModal,
  BTable,
} from "bootstrap-vue";

export default {
  name: "OutgoingStockForm",
  components: {
    vSelect,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BTable,
    BModal,
  },
  data() {
    return {
      mode: "add",
      nota: {
        id: null,
        no: "",
        requester: "",
        receiver: "",
        status: "",
        note: "",
      },
      outgoingStock: {
        items: [this.getNewItem()],
      },
      fields: [
        { key: "item_name", label: "Nama Barang" },
        { key: "item_code", label: "Kode Barang" },
        { key: "batch", label: "Batch" },
        { key: "current_stock", label: "Stok" },
        { key: "qty", label: "QTY" },
        { key: "actions", label: "Aksi" },
      ],
      itemOptions: [],
      allItems: [],
      isBusy: false,
    };
  },
  computed: {
    isReturnStatus() {
      return this.nota.status === "return";
    },
    isValidForm() {
      return (
        this.nota.receiver &&
        this.nota.status &&
        this.outgoingStock.items.length > 0 &&
        this.outgoingStock.items.every(
          (item) => item.item_name && item.item_code && item.batch && item.qty
        )
      );
    },
    totalQty() {
      return this.outgoingStock.items.reduce(
        (sum, item) => sum + Number(item.qty || 0),
        0
      );
    },
    modeTitle() {
      const titles = {
        edit: "Edit Data Barang Keluar",
        detail: "Detail Data Barang Keluar",
        add: "Tambah Data Barang Keluar",
      };
      return titles[this.mode];
    },
  },
  watch: {
    "nota.status"() {
      this.updateAllItems();
    },
    "outgoingStock.items": {
      handler() {
        this.outgoingStock.items.forEach((item) => {
          if (this.isReturnStatus && item.batch) {
            item.qty = item.incoming_stock;
          } else if (item.qty > item.current_stock) {
            item.qty = item.current_stock;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    onTotalQtyChange() {
      this.outgoingStock.items.forEach((item) => {
        if (this.isReturnStatus && item.batch) {
          item.qty = item.incoming_stock;
        } else if (item.qty > item.current_stock) {
          item.qty = item.current_stock;
        }
      });
    },
    async handleSubmit() {
      try {
        this.isBusy = true;
        const payload = {
          id: this.nota.id,
          requester: this.nota.requester,
          no: Date.now().toString(36) + (Math.random() + "").slice(2, 6),
          receiver: this.nota.receiver,
          status: this.nota.status,
          note: this.nota.note || null,
          items: this.outgoingStock.items.map(
            ({ id, item_name, item_code, batch, qty }) => ({
              id,
              item_name,
              item_code,
              batch,
              qty,
            })
          ),
        };
        const { data: response } = await outgoingGoodsAPI.add(payload);
        if (response.data) {
          this.showToast("Data Barang Keluar Berhasil Ditambahkan", "success");
          this.$router.push("/master-data/barang-keluar");
        }
      } catch (error) {
        this.showToast(
          error.response?.data?.message || "Terjadi kesalahan",
          "danger"
        );
      } finally {
        this.isBusy = false;
      }
    },
    async fetchBarang() {
      try {
        const { data: response } = await incomingGoodsTypeInventoryAPI.getAll();
        this.allItems = response.data.data;
        this.itemOptions = [
          ...new Set(this.allItems.map((item) => item.item_name)),
        ];
      } catch (error) {
        this.showToast(
          error.response?.data?.message || "Terjadi kesalahan",
          "danger"
        );
      }
    },
    getNewItem() {
      return {
        item_name: "",
        item_code: "",
        batch: "",
        qty: null,
        incoming_stock: 0,
        current_stock: 0,
        filteredCodeOptions: [],
        filteredBatchOptions: [],
      };
    },
    addItem() {
      this.outgoingStock.items.push(this.getNewItem());
      this.updateAllItems();
    },
    removeItem(index) {
      this.outgoingStock.items.splice(index, 1);
      this.updateAllItems();
    },
    updateItem(index, field) {
      const item = this.outgoingStock.items[index];
      if (field === "item_name") {
        item.filteredCodeOptions = [
          ...new Set(
            this.allItems
              .filter((i) => i.item_name === item.item_name)
              .map((i) => i.item_code)
          ),
        ];
        item.item_code = "";
        item.batch = "";
      } else if (field === "item_code") {
        this.updateBatchOptions(index);
      }
      this.updateStock(index);
    },
    updateBatchOptions(index) {
      const item = this.outgoingStock.items[index];
      const selectedBatches = this.outgoingStock.items
        .filter((_, i) => i !== index)
        .map((i) => i.batch);
      item.filteredBatchOptions = this.allItems
        .filter(
          (i) =>
            i.item_code === item.item_code &&
            !selectedBatches.includes(i.batch) &&
            (!this.isReturnStatus || i.incoming_stock === i.current_stock) &&
            i.status !== "hold"
        )
        .map((i) => ({
          value: i.batch,
          label: i.batch,
          incoming_stock: i.incoming_stock,
        }));
    },
    updateStock(index) {
      const item = this.outgoingStock.items[index];
      const selectedItem = this.allItems.find(
        (i) =>
          i.item_name === item.item_name &&
          i.item_code === item.item_code &&
          i.batch === item.batch
      );
      if (selectedItem) {
        item.current_stock = selectedItem.current_stock;
        item.incoming_stock = selectedItem.incoming_stock;
      } else {
        item.current_stock = 0;
        item.incoming_stock = 0;
      }
    },
    updateAllItems() {
      this.outgoingStock.items.forEach((_, index) => {
        this.updateBatchOptions(index);
        this.updateStock(index);
      });
    },
    showToast(message, variant) {
      this.$bvToast.toast(message, { title: "Pesan", variant, solid: true });
    },
    async getDetail(id) {
      try {
        this.isBusy = true;
        const { data: response } =
          await outgoingGoodsAPI.getDetailOutgoingGoods(id);
        this.nota = {
          id: response.data.id,
          no: response.data.no,
          sender: response.data.sender,
          receiver: response.data.receiver,
          status: response.data.status,
          note: response.data.note,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        };
        this.outgoingStock.items =
          response.data.outgoing_goods_type_inventory.map((item) => ({
            id: item.id,
            item_code: item.item_code,
            item_name: item.item_name,
            batch: item.batch,
            qty: item.total,
            note: item.note,
            current_stock: 0,
          }));
        this.updateAllItems();
      } catch (error) {
        this.showToast(
          error.response?.data?.message || "Terjadi kesalahan",
          "danger"
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
  async mounted() {
    await this.fetchBarang();
    this.onTotalQtyChange;
  },
};
</script>

<style scoped>
.w-75 {
  width: 75%;
}

.safari-input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.safari-input::-webkit-inner-spin-button,
.safari-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
